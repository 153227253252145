@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i");
/*
 * Animations
 */
@-webkit-keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@-webkit-keyframes hideSweetAlert {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
  }
}
@keyframes hideSweetAlert {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
  }
}
@-webkit-keyframes slideFromTop {
  0% {
    top: 0%;
  }
  100% {
    top: 50%;
  }
}
@keyframes slideFromTop {
  0% {
    top: 0%;
  }
  100% {
    top: 50%;
  }
}
@-webkit-keyframes slideToTop {
  0% {
    top: 50%;
  }
  100% {
    top: 0%;
  }
}
@keyframes slideToTop {
  0% {
    top: 50%;
  }
  100% {
    top: 0%;
  }
}
@-webkit-keyframes slideFromBottom {
  0% {
    top: 70%;
  }
  100% {
    top: 50%;
  }
}
@keyframes slideFromBottom {
  0% {
    top: 70%;
  }
  100% {
    top: 50%;
  }
}
@-webkit-keyframes slideToBottom {
  0% {
    top: 50%;
  }
  100% {
    top: 70%;
  }
}
@keyframes slideToBottom {
  0% {
    top: 50%;
  }
  100% {
    top: 70%;
  }
}
@-webkit-keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@-webkit-keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@-webkit-keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg);
  }
}
@keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg);
  }
}
.animateSuccessTip {
  -webkit-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s;
}
.animateSuccessLong {
  -webkit-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s;
}
.sa-icon.sa-success.animate::after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in;
}
@-webkit-keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    -webkit-transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    -webkit-transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    opacity: 1;
  }
}
.animateErrorIcon {
  -webkit-animation: animateErrorIcon 0.5s;
  animation: animateErrorIcon 0.5s;
}
@-webkit-keyframes animateXMark {
  0% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  50% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  80% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: -6px;
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes animateXMark {
  0% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  50% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  80% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: -6px;
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}
.animateXMark {
  -webkit-animation: animateXMark 0.5s;
  animation: animateXMark 0.5s;
}
@-webkit-keyframes pulseWarning {
  0% {
    border-color: #f8d486;
  }
  100% {
    border-color: #f8bb86;
  }
}
@keyframes pulseWarning {
  0% {
    border-color: #f8d486;
  }
  100% {
    border-color: #f8bb86;
  }
}
.pulseWarning {
  -webkit-animation: pulseWarning 0.75s infinite alternate;
  animation: pulseWarning 0.75s infinite alternate;
}
@-webkit-keyframes pulseWarningIns {
  0% {
    background-color: #f8d486;
  }
  100% {
    background-color: #f8bb86;
  }
}
@keyframes pulseWarningIns {
  0% {
    background-color: #f8d486;
  }
  100% {
    background-color: #f8bb86;
  }
}
.pulseWarningIns {
  -webkit-animation: pulseWarningIns 0.75s infinite alternate;
  animation: pulseWarningIns 0.75s infinite alternate;
}
@-webkit-keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.fc-icon:after {
  position: relative;
  margin: 0 -1em; /* ensures character will be centered, regardless of width */
}
.fc-icon-left-single-arrow:after {
  content: "\02039";
  font-weight: bold;
  font-size: 200%;
  top: -7%;
  left: 3%;
}
.fc-icon-right-single-arrow:after {
  content: "\0203A";
  font-weight: bold;
  font-size: 200%;
  top: -7%;
  left: -3%;
}
.fc-icon-left-double-arrow:after {
  content: "\000AB";
  font-size: 160%;
  top: -7%;
}
.fc-icon-right-double-arrow:after {
  content: "\000BB";
  font-size: 160%;
  top: -7%;
}
.fc-icon-left-triangle:after {
  content: "\25C4";
  font-size: 125%;
  top: 3%;
  left: -2%;
}
.fc-icon-right-triangle:after {
  content: "\25BA";
  font-size: 125%;
  top: 3%;
  left: 2%;
}
.fc-icon-down-triangle:after {
  content: "\25BC";
  font-size: 125%;
  top: 2%;
}
.fc-icon-x:after {
  content: "\000D7";
  font-size: 200%;
  top: 6%;
}
/* Buttons (styled <button> tags, normalized to work cross-browser)
--------------------------------------------------------------------------------------------------*/
.fc button {
  /* force height to include the border and padding */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* dimensions */
  margin: 0;
  height: 2.1em;
  padding: 0 0.6em;
  /* text & cursor */
  font-size: 13px;
  /* normalize */
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
  text-transform: uppercase;
}

/* Firefox has an annoying inner border */
.fc button::-moz-focus-inner {
  margin: 0;
  padding: 0;
}
.fc-state-default {
  /* non-theme */
  border: 1px solid;
}
.fc-state-default.fc-corner-left {
  /* non-theme */
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.fc-state-default.fc-corner-right {
  /* non-theme */
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
/* icons in buttons */
.fc button .fc-icon {
  /* non-theme */
  position: relative;
  top: -0.05em;
  /* seems to be a good adjustment across browsers */
  margin: 0 0.2em;
  vertical-align: middle;
}
/*
  button states
  borrowed from twitter bootstrap (http://twitter.github.com/bootstrap/)
*/
.fc-state-default {
  background-color: #f5f5f5;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ffffff),
    to(#e6e6e6)
  );
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  color: #333;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  outline: none;
}
.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: #333333;
  background-color: #e6e6e6;
}
.fc-state-hover {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}
.fc-state-down,
.fc-state-active {
  background-color: #009688;
  color: #fff;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.fc-state-disabled {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* Buttons Groups
--------------------------------------------------------------------------------------------------*/
.fc-button-group {
  display: inline-block;
}
/*
every button that is not first in a button group should scootch over one pixel and cover the
previous button's border...
*/
.fc .fc-button-group > * {
  /* extra precedence b/c buttons have margin set to zero */
  float: left;
  margin: 0 0 0 -1px;
}
.fc .fc-button-group > :first-child {
  /* same */
  margin-left: 0;
}

@media (min-width: 768px) {
  .app.sidenav-toggled .app-content {
    margin-left: 0;
  }
  .app.sidenav-toggled .app-sidebar {
    left: -230px;
  }
  .app.sidenav-toggled .app-sidebar__overlay {
    visibility: hidden;
  }
}

@media (max-width: 768px) {
  .app {
    overflow-x: hidden;
  }
  .app .app-sidebar {
    left: -230px;
  }
  .app .app-sidebar__overlay {
    visibility: hidden;
  }
  .app.sidenav-toggled .app-content {
    margin-left: 0;
  }
  .app.sidenav-toggled .app-sidebar {
    left: 0;
  }
  .app.sidenav-toggled .app-sidebar__overlay {
    visibility: visible;
  }
}
.app-content {
  min-height: calc(100vh - 50px);
  margin-top: 60px;
  background-color: #f3f3f3;
  -webkit-transition: margin-left 0.3s ease;
  -o-transition: margin-left 0.3s ease;
  transition: margin-left 0.3s ease;
}

@media (min-width: 768px) {
  .app-content {
    margin-left: 230px;
  }
}

@media (max-width: 768px) {
  .app-content {
    margin-top: 50px;
    min-width: 100%;
  }
}

@media (max-width: 480px) {
  .app-content {
    padding: 15px;
  }
}

@media print {
  .app-content {
    margin: 0;
    padding: 0;
    background-color: #fff;
  }
}
.light-text {
  font-weight: 300 !important;
}
.semibold-text {
  font-weight: 600 !important;
}
.line-head {
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}
/*----- Componant: Top Navigation Bar ----- */
.app-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1030;
  padding-right: 15px;
  height: 60px;
  background: #fff;
}

@media (min-width: 768px) {
  .app-header {
    padding-right: 15px;
  }
}

@media print {
  .app-header {
    display: none;
  }
}
.app-header__logo {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  color: #fff;
  text-align: center;
  padding: 0 15px;
  font-size: 24px;
  letter-spacing: 0.6px;
  font-family: "OpenSansSemi", Helvetica, Arial, sans-serif;
  line-height: 52px;
  -webkit-transition: left 0.3s ease, width 0.3s ease;
  -o-transition: left 0.3s ease, width 0.3s ease;
  transition: left 0.3s ease, width 0.3s ease;
}

@media (min-width: 768px) {
  .app-header__logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: block;
    width: 230px;
    background-color: #009dd9;
  }
}
.app-header__logo:focus,
.app-header__logo:hover {
  text-decoration: none;
  color: #fff;
}
.app-sidebar__toggle {
  padding: 18px;
  font-family: fontAwesome;
  color: #000;
  float: right;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
  .app-sidebar__toggle {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
}
.app-sidebar__toggle:before {
  content: "\f0c9";
  font-size: 18px;
}
.app-sidebar__toggle:focus,
.app-sidebar__toggle:hover {
  color: #8f8f8f;
  text-decoration: none;
}
.app-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .app-nav {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}
.app-nav__item {
  display: block;
  padding: 9px 15px;
  color: #ff8300;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.app-nav__item:hover,
.app-nav__item:focus {
  background: rgba(0, 0, 0, 0.1);
  color: #ff8300;
}
.app-search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  margin-right: 15px;
  padding: 10px 0;
}

@media (max-width: 480px) {
  .app-search {
    display: none;
  }
}
.app-search__input {
  border: 0;
  padding: 5px 10px;
  padding-right: 30px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.app-search__input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.app-search__input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.app-search__input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.app-search__input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.app-search__button {
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 10px;
  padding: 0 10px;
  border: 0;
  color: rgba(0, 0, 0, 0.8);
  background: none;
  cursor: pointer;
}
.app-notification {
  min-width: 270px;
}
.app-notification__title {
  padding: 8px 20px;
  text-align: center;
  background-color: rgba(0, 150, 136, 0.4);
  color: #333;
}
.app-notification__footer {
  padding: 8px 20px;
  text-align: center;
  background-color: #eee;
}
.app-notification__content {
  max-height: 220px;
  overflow-y: auto;
}
.app-notification__content::-webkit-scrollbar {
  width: 6px;
}
.app-notification__content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}
.app-notification__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 20px;
  color: inherit;
  border-bottom: 1px solid #ddd;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.app-notification__item:focus,
.app-notification__item:hover {
  color: inherit;
  text-decoration: none;
  background-color: #e0e0e0;
}
.app-notification__message,
.app-notification__meta {
  margin-bottom: 0;
}
.app-notification__icon {
  padding-right: 10px;
}
.app-notification__message {
  line-height: 1.2;
}
.app-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 90px;
  width: 230px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;
  background-color: #3e484e;
  -webkit-transition: left 0.3s ease, width 0.3s ease;
  -o-transition: left 0.3s ease, width 0.3s ease;
  transition: left 0.3s ease, width 0.3s ease;
}
.app-sidebar::-webkit-scrollbar {
  width: 6px;
}
.app-sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

@media print {
  .app-sidebar {
    display: none;
  }
}

@media (max-width: 768px) {
  .app-sidebar__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
  }
}
.app-sidebar__user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  color: #fff;
}
.app-sidebar__user-avatar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 15px;
}
.app-sidebar__user-name {
  font-size: 17px;
  line-height: 1.3;
}
.app-sidebar__user-name,
.app-sidebar__user-designation {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
.app-menu {
  margin-bottom: 0;
  padding-top: 0px;
}
.app-menu__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px;
  font-size: 14px;
  /*  border-left: 3px solid transparent;
*/
  -webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
  -o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
  transition: border-left-color 0.3s ease, background-color 0.3s ease;
  color: #fff;
}
.app-menu__item.active,
.app-menu__item:hover,
.app-menu__item:focus {
  background: #363e44;
  /*  border-left-color: #009688;*/
  text-decoration: none;
  color: #fff;
}
.app-menu__icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25px;
}
.app-menu__label {
  word-wrap: break-word;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.treeview.is-expanded [data-toggle="treeview"] {
  /*border-left-color: #009688;*/
  background: #009dd9;
}
.treeview.is-expanded .treeview-menu {
  max-height: 100vh;
}
.treeview.is-expanded .treeview-indicator {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.submenu .multi-level {
  max-height: 0vh;
  display: none;
}
.submenu.is-expanded .multi-level {
  max-height: 100vh;
  display: block;
}

.treeview-menu {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease;
  -o-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
  background: #2a383e;
}
.treeview-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 10px 10px 20px;
  font-size: 13px;
  color: #8b959b;
}
.treeview-item.active,
.treeview-item:hover,
.treeview-item:focus {
  background: #0d1214;
  text-decoration: none;
  color: #fff;
}
.treeview-item .icon {
  margin-right: 5px;
}
.treeview-indicator {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

@media (min-width: 768px) {
  .sidebar-mini.sidenav-toggled .app-sidebar__user-name,
  .sidebar-mini.sidenav-toggled .app-sidebar__user-designation,
  .sidebar-mini.sidenav-toggled .treeview-indicator {
    display: none;
  }
  .sidebar-mini.sidenav-toggled .app-sidebar__user-avatar {
    width: 30px;
    height: 30px;
  }
  .sidebar-mini.sidenav-toggled .app-content {
    margin-left: 50px;
  }
  .sidebar-mini.sidenav-toggled .app-header__logo {
    left: 0;
    width: 50px;
    overflow: hidden;
    padding: 0px 2px;
    -webkit-transition: left 0.3s ease, width 0.3s ease;
    -o-transition: left 0.3s ease, width 0.3s ease;
    transition: left 0.3s ease, width 0.3s ease;
  }
  .sidebar-mini.sidenav-toggled .app-sidebar {
    left: 0;
    width: 50px;
    overflow: hidden;
  }
  .sidebar-mini.sidenav-toggled .app-sidebar:hover {
    overflow: visible;
  }
  .sidebar-mini.sidenav-toggled .app-menu__item {
    overflow: hidden;
  }
  .sidebar-mini.sidenav-toggled .app-menu__item:hover {
    overflow: visible;
  }
  .sidebar-mini.sidenav-toggled .app-menu__item:hover .app-menu__label {
    opacity: 1;
  }
  .sidebar-mini.sidenav-toggled .app-menu__item:hover + .treeview-menu {
    visibility: visible;
  }
  .sidebar-mini.sidenav-toggled .app-menu__label {
    display: block;
    position: absolute;
    top: 0;
    left: 53px;
    min-width: 210px;
    padding: 5px 10px;
    margin-left: -3px;
    line-height: 18px;
    opacity: 0;
    height: 46px;
    background: #363e44;
  }
  .sidebar-mini.sidenav-toggled .treeview:hover .app-menu__item {
    overflow: visible;
    background: #363e44;
    /*    border-left-color: #009688;*/
    color: #fff;
  }

  .sidebar-mini.sidenav-toggled .submenu .app-menu__label {
    padding: 11px 19px;
    left: 2px;
    background: #2a383e;
  }
  .sidebar-mini.sidenav-toggled .submenu .treeview-menu {
    left: 0px;
    padding: 0px;
  }
  .sidebar-mini.sidenav-toggled .submenu:hover .treeview-menu {
    position: relative;
  }

  .sidebar-mini.sidenav-toggled .treeview:hover .app-menu__label {
    opacity: 1;
  }

  .sidebar-mini.sidenav-toggled .treeview:hover .treeview-menu {
    max-height: 100vh;
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }

  .sidebar-mini.sidenav-toggled .treeview-menu {
    position: absolute;
    left: 50px;
    min-width: 210px;
    padding: 12px 0;
    opacity: 0;
    z-index: 9;
    visibility: hidden;
    -webkit-transition: visibility 0.3s ease;
    -o-transition: visibility 0.3s ease;
    transition: visibility 0.3s ease;
  }
}
.dropdown-menu {
  border-radius: 0;
  -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.dropdown-menu.dropdown-menu-right {
  left: auto;
  right: 0;
}
.dropdown-item .fa,
.dropdown-item .icon {
  margin-right: 5px;
  vertical-align: middle;
}
.btn {
  cursor: pointer;
}
.btn:not([disabled]):not(.disabled):not(.btn-link):hover,
.btn:not([disabled]):not(.disabled):not(.btn-link):focus {
  /*  text-decoration: none;
  -webkit-transform: translate3d(0, -1px, 0);
          transform: translate3d(0, -1px, 0);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);*/
}

.btn .icon,
.btn .fa {
  font-size: 14px;
  margin-right: 5px;
  vertical-align: middle;
}
/* Custom Checkbox and Radio Buttons */
.animated-checkbox input[type="checkbox"] {
  display: none;
}
.animated-checkbox input[type="checkbox"] + .label-text {
  cursor: pointer !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.animated-checkbox input[type="checkbox"] + .label-text:before {
  content: "";
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  width: 1em;
  display: inline-block;
  margin-right: 2px;
  vertical-align: -2px;
}
.animated-checkbox input[type="checkbox"]:checked + .label-text:before {
  content: "";
  color: #009688;
  -webkit-animation: tick 180ms ease-in;
  animation: tick 180ms ease-in;
}
.animated-checkbox input[type="checkbox"]:disabled + .label-text {
  cursor: not-allowed !important;
}
.animated-checkbox input[type="checkbox"]:disabled + .label-text:before {
  content: "";
  color: #ccc;
}
.animated-radio-button input[type="radio"] {
  display: none;
}
.animated-radio-button input[type="radio"] + .label-text {
  cursor: pointer !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.animated-radio-button input[type="radio"] + .label-text:before {
  content: "";
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  width: 1em;
  display: inline-block;
  margin-right: 2px;
  vertical-align: -2px;
}
.animated-radio-button input[type="radio"]:checked + .label-text:before {
  content: "";
  color: #009688;
  -webkit-animation: tick 180ms ease-in;
  animation: tick 180ms ease-in;
}
.animated-radio-button input[type="radio"]:disabled + .label-text {
  cursor: not-allowed !important;
}
.animated-radio-button input[type="radio"]:disabled + .label-text:before {
  content: "";
  color: #ccc;
}
@-webkit-keyframes tick {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  90% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes tick {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  90% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.toggle.lg input[type="checkbox"] + .button-indecator:before {
  font-size: 30px;
}
.toggle input[type="checkbox"] {
  display: none;
}
.toggle input[type="checkbox"] + .button-indecator {
  cursor: pointer;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.toggle input[type="checkbox"] + .button-indecator:before {
  content: "";
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 25px;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  margin-right: 5px;
  vertical-align: -2px;
}
.toggle input[type="checkbox"]:checked + .button-indecator:before {
  content: "";
  color: #009688;
  -webkit-animation: toggleBtn 0.3s ease-in-out;
  animation: toggleBtn 0.3s ease-in-out;
}
.toggle input[type="checkbox"]:disabled + .button-indecator {
  cursor: not-allowed !important;
}
.toggle input[type="checkbox"]:disabled + .button-indecator:before {
  color: #ccc;
}
.toggle-flip input[type="checkbox"] {
  display: none;
}
.toggle-flip input[type="checkbox"] + .flip-indecator {
  position: relative;
  width: 60px;
  height: 30px;
  display: block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-perspective: 90px;
  perspective: 90px;
}
.toggle-flip input[type="checkbox"] + .flip-indecator:before,
.toggle-flip input[type="checkbox"] + .flip-indecator:after {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  line-height: 30px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: center;
  border-radius: 3px;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.toggle-flip input[type="checkbox"] + .flip-indecator:before {
  content: attr(data-toggle-off);
  background-color: #ddd;
}
.toggle-flip input[type="checkbox"] + .flip-indecator:after {
  content: attr(data-toggle-on);
  background-color: #009688;
  color: #fff;
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.toggle-flip input[type="checkbox"]:checked + .flip-indecator:before {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.toggle-flip input[type="checkbox"]:checked + .flip-indecator:after {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.toggle-flip input[type="checkbox"]:disabled + .flip-indecator {
  cursor: not-allowed !important;
  color: #ccc;
}
@-webkit-keyframes toggleBtn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes toggleBtn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.material-half-bg {
  height: 100vh;
  background-color: #e7e7e7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.material-half-bg .cover {
  background-color: #009688;
  height: 50vh;
}
.m-loader {
  position: relative;
  width: 40px;
}
.m-loader:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.m-circular {
  -webkit-animation: rotate 1.5s linear infinite;
  animation: rotate 1.5s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke: #009688;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}
@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
.btn-spinner {
  display: inline-block;
  z-index: 2000;
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  margin-right: 5px;
  border: solid 2px transparent;
  border-top-color: #fff;
  border-left-color: #fff;
  border-radius: 10px;
  -webkit-animation: bSpinner 0.8s linear infinite;
  animation: bSpinner 0.8s linear infinite;
}
@-webkit-keyframes bSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes bSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.app-nav__item,
.fa-user {
  padding-right: 10px;
}
.app-nav__item,
.fa-angle-down {
  padding-left: 10px;
}
.app-nav__item a {
  text-decoration: none;
}
.sidenav-toggled .fa-angle-left {
  display: none;
}
.fa-angle-left {
  -moz-transition: transform 1s;
  -webkit-transition: transform 1s;
  transition: transform 1s;
  transform: rotate(-90deg);
}
.flip {
  transform: rotate(0deg);
}

@media (min-width: 768px) {
  .sidenav-toggled .pt-min {
    padding-top: 13px !important;
  }
}
