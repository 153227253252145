@charset "utf-8";
/* CSS Document */

/*

1. font-family
2. font-size
3. font-weight
4. text-colors
5. border-style
6. table-column-width
7. button-style
8. line-height
9. loader-style

*/

/*font-family*/

.open-sans-semi {
  font-family: "OpenSansSemi", Helvetica, Arial, sans-serif;
}

.open-sans-bold {
  font-family: "OpenSansBold", Helvetica, Arial, sans-serif;
}

/*font-style*/
.font-uppercase {
  text-transform: uppercase;
  letter-spacing: 3px;
}

/*font-size*/

.fs11 {
  font-size: 11px !important;
}

.fs12 {
  font-size: 12px !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs18 {
  font-size: 18px !important;
}

.fs22 {
  font-size: 22px !important;
}

.fs26 {
  font-size: 26px !important;
}

.fs30 {
  font-size: 30px !important;
}

.fs34 {
  font-size: 34px !important;
}

.fs38 {
  font-size: 38px !important;
}

.fs42 {
  font-size: 42px !important;
}

.fs46 {
  font-size: 46px !important;
}

.fs50 {
  font-size: 50px !important;
}

/*font-weight*/

.fw-normal {
  font-weight: normal !important;
}

.fw-semi-bold {
  font-weight: 600;
}

.fw-bold {
  font-weight: bold;
}

/* text colors*/

.txt-red {
  color: #da491c;
}

.txt-grey {
  color: #848484;
}

.txt-blue {
  color: #009dd9;
}

.txt-black {
  color: #000;
}

.txt-white {
  color: #fff;
}

.txt-green {
  color: #24a028;
}

.txt-orange {
  color: #ed721e;
}

.txt-violet {
  color: #b21dac;
}

/* border-style */

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border {
  border: 1px solid #e8e8e8;
}

.btnone {
  border-top: 0px !important;
}

.brnone {
  border-right: 0px !important;
}

.bbnone {
  border-bottom: 0px !important;
}

.blnone {
  border-left: 0px !important;
}

.border-white {
  border-right: 1px solid #ffffff;
}

/* table-column-width */

.col-5 {
  width: 5%;
}

.col-10 {
  width: 10%;
}

.col-15 {
  width: 15%;
}

.col-20 {
  width: 20%;
}

.col-25 {
  width: 25%;
}

.col-30 {
  width: 30%;
}

.col-35 {
  width: 35%;
}

.col-40 {
  width: 40%;
}

.col-45 {
  width: 45%;
}

.col-50 {
  width: 50%;
}

.col-55 {
  width: 55%;
}

.col-60 {
  width: 65%;
}

.col-70 {
  width: 70%;
}

.col-75 {
  width: 75%;
}

/* button-style */

.btn {
  font-weight: normal;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  cursor: pointer;
}

.btn-blue {
  background: #009dd9;
}

.btn-grey {
  background: #dddddd;
}

.btn-orange {
  background: #ff8300;
  color: #fff;
}

.btn-white {
  background: #fff;
  border: 1px solid #009dd9;
}

.btn-white:hover {
  background: #009dd9;
  border: 1px solid #009dd9;
  color: #fff;
}

.btn-reset {
  background: none;
  border: none;
  color: #6e6e6e;
  cursor: pointer;
  font-weight: normal;
}

.btn[disabled] {
  cursor: not-allowed;
}

.btn:hover[disabled] {
  background: #fff;
  border: 1px solid #009dd9;
  color: #009dd9;
}

.btn-save {
  background: #009dd9;
  border: 1px solid #009dd9;
  color: #fff;
}

.btn-save:hover {
  background: #fff;
  border: 1px solid #009dd9;
  color: #009dd9;
}

/*--line-height---*/

.lh20 {
  line-height: 20px;
}

.lh25 {
  line-height: 25px;
}

.lh30 {
  line-height: 30px;
}

.lh35 {
  line-height: 35px;
}

.lh40 {
  line-height: 40px;
}

.lh45 {
  line-height: 45px;
}

.lh50 {
  line-height: 50px;
}

/* margin */

.mt15 {
  margin-top: 15px;
}

.mt10 {
  margin-top: 10px;
}

.mt5 {
  margin-top: 5px;
}

/* padding  */

.pb20 {
  padding-bottom: 20px;
}

.clear {
  clear: both;
}

.ws {
  white-space: nowrap;
}

.img-fullscreen {
  width: 100%;
}

input::placeholder {
  font-size: 11px;
}

ul,
ul li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

a {
  outline: none;
}

/* Container */

.app-header {
  padding-right: 0px;
}

h1 {
  padding-bottom: 5px;
  border-bottom: 1px solid #b21dac;
  display: inline-block;
  margin-bottom: 0px;
}

.main-footer {
  padding: 10px 15px;
  background: #707276;
  color: #fff;
  font-size: 12px;
  transition: all 0.3s !important;
  width: 100%;
}

/* position: absolute; width: 100%; bottom: 0px; */

.programs {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.bg-grey {
  background-color: #f3f3f3;
}

/* Form-control */

.form-control {
  border-radius: 0px;
  border: 0px;
  border-top: 2px solid #b9e4fb;
  border-bottom: 1px solid #ededed;
}

.form-control:focus {
  border-top: 2px solid #009dd9;
  box-shadow: 0px 0px 0px;
  outline: none;
}

.fs-wrap {
  border-top: 2px solid #b9e4fb;
  border-bottom: 1px solid #ededed;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.fs-open {
  border-top: 2px solid #0099e9;
  box-shadow: 0px 0px 0px;
  outline: none;
}

/* Daily QC Search */

.search-input {
  padding-right: 28px;
}

.search-icon {
  position: absolute;
  top: 7px;
  right: 10px;
}

.ui-datepicker-trigger {
  border: none;
  background: none;
  position: absolute;
  right: 0px;
  top: 30px;
  color: #929292;
}

.fs-label {
  font-size: 12px;
}

/* User-Allocator-Section */

/* Name-list */
/* .name-list {height: 550px; overflow-y: scroll} */
.name-list {
  height: 550px;
  overflow-y: auto;
}

.name-lst {
  height: 1111px;
  overflow-y: auto;
}

.name-list ul {
  padding: 0px;
  margin: 0px;
}

.name-list ul.connected {
  padding: 0px;
  margin: 0px;
  min-height: 500px;
}

.name-list ul li {
  font-size: 12px;
  list-style: none;
  background: #fff;
}

.temp-header {
  position: absolute;
  top: 49%;
  border-top: 1px solid #dee2e6;
  z-index: 9;
}

.all-editor {
  background: #c7dcf7 !important;
  color: #4a89dc;
  border: 1px solid #5d9cec;
}

.allocate-col-1 {
  color: #e9573f;
  border: 1px solid #e9573f;
}

.allocate-col-2 {
  color: #d39c2f;
  border: 1px solid #d39c2f;
}

.allocate-col-3 {
  color: #37bc9b;
  border: 1px solid #37bc9b;
}

.allocate-col-4 {
  color: #8cc152;
  border: 1px solid #8cc152;
}

.allocate-col-5 {
  color: #8c8525;
  border: 1px solid #8c8525;
}

.allocate-col-6 {
  color: #99a534;
  border: 1px solid #99a534;
}

.allocator-header {
  background: #fff;
  -webkit-box-shadow: 0px 5px 10px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 10px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 10px -6px rgba(0, 0, 0, 0.75);
  position: relative;
  z-index: 8;
}

.allocate-col-1-bg {
  background: #fae1de;
  color: #e9573f;
}

.allocate-col-2-bg {
  background: #fceed2;
  color: #d39c2f;
}

.allocate-col-3-bg {
  background: #d0efe7;
  color: #37bc9b;
}

.allocate-col-4-bg {
  background: #e2efd4;
  color: #8cc152;
}

.allocate-col-5-bg {
  background: #fefac0;
  color: #8c8525;
}

.allocate-col-6-bg {
  background: #e9f29b;
  color: #99a534;
}

.user-name {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-auto {
  cursor: auto;
}

.assign-date {
  background: #fff;
  width: 100%;
  position: absolute;
  left: 2px;
  z-index: 9;
  padding: 15px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 157, 217, 1);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 157, 217, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 157, 217, 1);
}

.slidingDiv {
  width: 100%;
  position: absolute;
  left: 3px;
  top: 47px;
}

.slidingDiv2 {
  width: 100%;
  top: 72px;
  position: absolute;
  left: 0;
}

.temp-allo-wrap {
  position: relative;
}

.temp-allo {
  color: #000;
  display: none;
  width: auto;
  background: #ccc;
  position: absolute;
  top: 0;
  left: -242px;
  z-index: 100;
  background: #fff;
  padding: 15px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 157, 217, 1);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 157, 217, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 157, 217, 1);
}

.temp-allo span {
  color: #8b959b;
  display: block;
  font-style: italic;
}

.form-group {
  position: relative;
}

/* User-Slider*/

.carousel {
  margin-bottom: 0;
}

.carousel-control {
  right: 0px;
}

.carousel-item label {
  display: inline-block;
  margin-bottom: 0px;
  cursor: pointer;
}

/* .carousel-item img{height: 700px;} */
.carousel-control.right {
  right: -12px;
}

.carousel-indicators {
  right: 50%;
  top: auto;
  bottom: 0;
  margin-right: -19px;
}

.carousel-indicators li {
  background: silver;
}

.carousel-indicators .active {
  background: #333;
}

.carousel-control-prev .fa-angle-left,
.fa-angle-right {
  transform: rotate(0deg);
  display: block;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 1;
  width: 1.44%;
  background: #f1f1f1;
  font-size: 18px;
  color: #aaaaaa;
  transition: all 0.3s !important;
  min-height: 46px;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  background: #009dd9;
  color: #fff;
  opacity: 1;
}

/* Timeline*/

ul.timeline {
  list-style-type: none;
  position: relative;
  margin: 0;
  padding: 0;
}

ul.timeline:before,
ul.timeline>li:before {
  content: " ";
  display: inline-block;
  position: absolute;
  z-index: 400;
}

ul.timeline:before {
  background: #d4d9df;
  left: 0;
  width: 2px;
  height: 100%;
  margin-top: 5px;
}

ul.timeline>li {
  margin: 20px 0;
  padding-left: 20px;
}

ul.timeline>li.grey:before {
  background: #cccccc;
  border-radius: 50%;
  left: -3px;
  width: 8px;
  height: 8px;
  margin-top: 5px;
}

ul.timeline>li.green:before {
  background: #37bc9b;
  border-radius: 50%;
  left: -3px;
  width: 8px;
  height: 8px;
  margin-top: 5px;
}

ul.timeline>li.dark-yellow:before {
  background: var(--my-color-var);
  border-radius: 50%;
  left: -3px;
  width: 8px;
  height: 8px;
  margin-top: 5px;
}

/* Drag and Drop*/

.connected,
.exclude,
.handles,
.sortable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sortable.grid {
  overflow: hidden;
}

.connected li,
.exclude li,
.handles li,
.sortable li {
  list-style: none;
}

.handles span {
  cursor: move;
}

li.disabled {
  opacity: 0.5;
}

.sortable.grid li {
  line-height: 80px;
  float: left;
  width: 80px;
  height: 80px;
  text-align: center;
}

li.highlight {
  background: #fee25f;
}

.circle-pnl {
  overflow: hidden;
}

.circle-pnl ul {
  margin: 0px;
  padding: 0px;
}

.circle-pnl ul li {
  float: left;
}

.circle-pnl ul li.red {
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.circle-pnl ul li.green {
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.circle-pnl ul li.dark-yellow {
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

/* Tab-style */

.nav-tabs .nav-item {
  margin-right: 35px;
  width: auto;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #ed721e;
  border-bottom: 1px solid #ed721e;
  border-top: #fff;
}

.nav-tabs .nav-link {
  border-top: 0px;
  font-size: 12px;
}

button:focus {
  outline: 0px;
}

.calender-icon {
  position: absolute;
  right: 11px;
  top: 35px;
}

.ddl-year select {
  z-index: 9;
  background: #fff url(../images/select-arrow.png) no-repeat top 12px right 9px;
  position: relative;
  -webkit-appearance: none;
  border-top: 0px;
  border-bottom: 0px;
  width: 100%;
  border-top: 2px solid #b9e4fb;
  border-bottom: 1px solid #ededed;
}

.ddl-year select:focus {
  border-top: 2px solid #0099e9;
  box-shadow: 0 0 0;
  outline: 0;
}

.ddl-select select {
  z-index: 9;
  background: #fff url(../images/select-arrow.png) no-repeat top 12px right 9px;
  position: relative;
  -webkit-appearance: none;
  border-top: 0px;
  border-bottom: 0px;
  width: 50%;
}

.ddl-select select:focus {
  border-top: 0px;
  border-top: 0px;
}

/* Daily qc */

.nav-tabs {
  background: #f1f0f0;
}

.qc-calender {
  border-bottom: 1px solid #b21dac;
  opacity: 0.2;
}

.gallery-header {
  border-bottom: 1px solid #dee2e6;
  overflow: hidden;
}

.ui-datepicker-range>.ui-state-default {
  background: #009dd9 !important;
  color: #fff;
}

/* Modals-popup */

.modal-content {
  border-radius: 0px;
}

.modal-footer {
  border-top: none;
}

.app-menu__item {
  font-size: 13px;
}

/* Single-vie-img */

.panel-slider-right.active {
  width: 8px;
}

.panel-slider-right.active .img-pro-content {
  display: none;
}

.container-panel-right {
  width: calc(87% - 0px) !important;
}

.container-panel-right.active {
  width: 99% !important;
}

.arrow-right {
  left: 0px !important;
  width: 8px;
}

.img-pro-arrow-bg {
  background: #d7dfe5;
}

.img-pro-content {
  background: #e4e8eb;
  height: 100%;
  margin-left: 8px;
  overflow: hidden;
}

.img-pro-content ul {
  overflow: hidden;
}

.img-pro-content ul li {
  width: 100%;
  overflow: hidden;
}

/* gallery-img */

.gallery-img-width {
  width: 185px;
  transition: transform 0.3s;
}

.gallery-img-width:hover {
  transform: scale(1.2);
}

.gallery-img {
  width: 150px;
  height: 150px;
  border: 1px solid #ccc;
}

.gallery-pro-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Img-property-panel */

.comment-panel h5:after {
  content: "";
  display: block;
  border-bottom: 1px solid #009dd9;
}

.comment-content {
  background: #fff;
}

.comment-wrapper {
  max-height: 200px;
  overflow: auto;
}

.msg-icon {
  position: absolute;
  right: 20px;
  bottom: 8px;
}

.msg-icon i {
  transform: rotate(50deg);
}

.icon-deactive {
  opacity: 0.5;
}

.btn-img-pro {
  background: #fff;
  width: 100%;
  text-align: left;
  border: 1px solid #cccccc;
  font-size: 12px;
}

.btn-edit {
  margin-bottom: 4px;
  color: #009dd9;
}

.btn-edit:hover {
  color: #fff;
  background: #009dd9;
}

.btn-edit:before {
  content: "\f040";
  display: inline-block;
  border-radius: 50px;
  background: #009dd9;
  color: #fff;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  text-align: center;
  font: normal normal normal 12px/1 FontAwesome;
  padding-top: 3px;
  border: 1px solid #009dd9;
}

.btn-edit:hover:before {
  border-radius: 50px;
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
}

.btn-reject {
  margin-bottom: 4px;
  color: #d60037;
}

.btn-reject:hover {
  background: #d60037;
  color: #fff;
}

.btn-reject:before {
  content: "\f00d";
  display: inline-block;
  border-radius: 50px;
  background: #d60037;
  color: #fff;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  text-align: center;
  font: normal normal normal 12px/1 FontAwesome;
  padding-top: 3px;
  border: 1px solid #d60037;
}

.btn-reject:hover:before {
  border-radius: 50px;
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
}

.btn-approve {
  margin-bottom: 4px;
  color: #85c63f;
}

.btn-approve:hover {
  background: #85c63f;
  color: #fff;
}

.btn-approve:before {
  content: "\f00c";
  display: inline-block;
  border-radius: 50px;
  background: #85c63f;
  color: #fff;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  text-align: center;
  font: normal normal normal 12px/1 FontAwesome;
  padding-top: 3px;
  border: 1px solid #85c63f;
}

.btn-approve:hover:before {
  border-radius: 50px;
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
}

.btn-improvisation {
  color: #b21dac;
}

.btn-improvisation:hover {
  background: #b21dac;
  color: #fff;
}

.btn-improvisation:before {
  content: "\f044";
  display: inline-block;
  border-radius: 50px;
  background: #b21dac;
  color: #fff;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  text-align: center;
  font: normal normal normal 12px/1 FontAwesome;
  padding-top: 3px;
  border: 1px solid #b21dac;
}

.btn-improvisation:hover:before {
  border-radius: 50px;
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
}

.btn-bulk-action {
  background: #fff;
  text-align: left;
  border: 1px solid #cccccc;
  font-size: 14px;
}

/* Img-gallery-slider */

.img-gallery-carousel-inner {
  width: 100%;
  margin: 0 auto;
}

.img-gallery-arrow-left,
.img-gallery-arrow-right {
  position: absolute;
  top: 45%;
  width: 35px;
  height: 50px;
  background: #000;
  opacity: 0.5;
}

.img-gallery-arrow-left:hover,
.img-gallery-arrow-right:hover {
  opacity: 1;
  background: #000;
}

.img-gallery-arrow-left i,
.img-gallery-arrow-right i {
  opacity: 1 !important;
}

iframe {
  outline: none;
  border: none;
}

.img-close-btn {
  position: absolute;
  right: 7px;
  top: 0px;
  position: absolute;
  z-index: 9;
}

.img-close-btn button.close {
  color: #fff;
}

.view-close-icon {
  position: relative;
  right: 0px;
  top: 0px;
  cursor: pointer;
}

.view-close-icon-toggle {
  position: absolute;
  right: 0px;
  top: -25px;
}

.fullView-close-icon-toggle {
  position: absolute;
  right: 15px;
  top: -34px;
}

/* Third level menu */

.submenu a {
  padding: 10px 10px 10px 35px;
  background: #2a383e !important;
  font-size: 13px;
  color: #8b959b;
}

.submenu a:hover {
  padding: 10px 10px 10px 35px;
  background: #000 !important;
  font-size: 13px;
  color: #fff;
}

.submenu a i {
  color: #fff;
}

.submenu a i:hover {
  color: #fff;
}

.submenu a i.fa-angle-left {
  transform: rotate(0deg);
}

.submenu a i.fa-angle-left {
  transform: rotate(-90deg);
}

.treeview .fa-angle-left {
  transform: rotate(0deg);
}

.is-expanded .fa-angle-left {
  transform: rotate(-90deg);
}

.multi-level {
  display: none;
}

.multi-level a {
  padding: 10px 10px 10px 35px;
  color: #c5c5c5;
  background: #2a383e !important;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.multi-level a:hover {
  padding: 10px 10px 10px 35px;
  color: #fff;
}

/*  Accordion Panel */

.card {
  border: none;
  border-radius: 0px;
}

.card-header {
  border-bottom: 1px solid #e4e8eb;
  background-color: #fff;
  padding: 10px 15px;
  cursor: pointer;
}

.card-body {
  padding: 10px 0px;
}

.accordion .card-header:after {
  font-family: "FontAwesome";
  content: "\f068";
  float: right;
  color: #009dd9;
  font-size: 15px;
  font-weight: 500;
}

.card-header a:hover {
  color: #b21dac !important;
}

.accordion .card-header.collapsed:after {
  content: "\f067";
}

.week-table td {
  padding: 10px 8px;
  border-top: 0px;
}

.week-table-footer {
  background: #f7f6f6;
}

.img-zoom-panel {
  display: inline-block;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(0%);
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

/* Loader-Style */

#loader:after,
#loader:before {
  content: "";
  position: absolute;
}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 70px;
  height: 70px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #009dd9;
  -webkit-animation: 2s linear infinite spin;
  animation: 2s linear infinite spin;
}

#loader:before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #d60037;
  -webkit-animation: 3s linear infinite spin;
  animation: 3s linear infinite spin;
}

#loader:after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #85c63f;
  -webkit-animation: 1.5s linear infinite spin;
  animation: 1.5s linear infinite spin;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* - React custom style - */

/* React multiselect custom style */

.picky__input {
  border-radius: 0px;
  border: 0px;
  border-top: 2px solid #b9e4fb;
  border-bottom: 1px solid #ededed;
  padding: 5px 35px 5px 5px;
  color: #495057;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.picky__input:focus {
  border-top: 2px solid #009dd9;
  box-shadow: 0px 0px 0px;
  outline: none;
}

.picky__filter {
  padding: 0px;
}

.picky__filter__input {
  padding: 5px 8px;
  height: 36px;
  border: 0px;
  border-bottom: 1px solid #eee;
  border-radius: 0px;
  font-size: 13px;
}

.picky__filter__input:focus {
  outline: 0px;
}

.picky__placeholder {
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: -webkit-box;
}

.picky__dropdown .option:focus {
  outline: 0px !important;
}

.picky__dropdown .option:hover {
  background-color: #fff;
}

.picky__dropdown .option input[type="checkbox"] {
  margin-right: 10px;
}

.picky__dropdown .option.selected,
.picky__dropdown li.selected {
  background: #fff;
}

.picky__input::after {
  background: #fff url(../images/select-arrow.png) no-repeat;
  right: 10px;
  top: 11px;
  width: 15px;
  height: 10px;
  border-top: 0px solid;
  border-right: 0px solid transparent;
  border-left: 0px solid transparent;
}

.picky__dropdown .option,
.picky__dropdown li {
  font-size: 13px;
  color: #495057;
}

.apply-filter-block {
  text-align: right;
  padding-bottom: 20px;
}

/* Checkbox custom style */

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  border: 1px solid #aeaeae;
  background: #fff;
  border-radius: 3px;
  position: relative;
  top: 4px;
}

input[type="checkbox"]:after {
  content: "";
  position: relative;
  left: 40%;
  top: 19%;
  width: 27%;
  height: 52%;
  border: 1px solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

input[type="checkbox"]:checked:after {
  display: block;
}

input[type="checkbox"]:checked {
  border: 1px solid #aeaeae;
  background: #3e97eb;
}

/* Tab custom style */

.react-tabs__tab {
  padding: 10px 0px;
  margin-right: 35px;
}

.react-tabs__tab-list {
  border-bottom: 0px solid #aaa;
  padding: 0px;
  padding-left: 15px;
  background: #f1f0f0;
  font-size: 12px;
  padding-top: 15px;
}

.react-tabs__tab--selected {
  color: #ed721e;
  border-color: #f1f0f0;
  border-bottom: 1px solid #ed721e;
  border-radius: 0;
  background: none;
}

/* .react-tabs__tab:focus {box-shadow: none; border-color: #f1f0f0 #f1f0f0 #ed721e; border-bottom: 1px solid #ed721e;} */
.react-tabs__tab:focus:after {
  height: 4px;
}

/* Table custom style */

.ReactTable {
  border: 0px;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border-right: 0px;
  padding: 15px;
  font-size: 12px;
  color: #009dd9;
  font-weight: 600;
  text-align: left;
}

.ReactTable .rt-thead.-header {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid #009dd9;
}

.ReactTable .rt-thead.-filters .rt-th {
  padding: 15px 10px;
  border-right: 0px;
}

.ReactTable .rt-tbody .rt-td {
  font-size: 12px;
  padding: 15px;
  border-right: 0px;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: none;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  white-space: normal;
}

/* Table footer style */

.pagination-bottom {
  width: 70%;
  margin: 10px auto 0px;
}

.ReactTable .-pagination {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  border-top: 0px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .-pagination .-btn {
  display: inline-block;
  border-radius: 0px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  width: 110px;
  padding: 8px 0px;
  color: rgb(0, 0, 0);
  margin-right: 5px;
  font-size: 13px;
  height: auto;
  margin-right: 0px;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  border: 1px solid #3e97eb;
  color: #3e97eb;
  background: rgb(255, 255, 255);
}

.ReactTable .-pagination .-pageJump input {
  width: 50px;
  border-radius: 0px;
  margin-left: 20px;
  margin-right: 15px;
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border-radius: 0px;
  padding: 7px;
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 0px;
}

.ReactTable .-pagination .-pageInfo {
  margin: 0px;
  margin-right: 15px;
}

.ReactTable .-pagination .-previous {
  text-align: right;
}

.ReactTable .-pagination .-next {
  text-align: left;
}

.ReactTable .-pagination .-center {
  display: inline-block;
}

/* Modal pop */

.ReactModal__Overlay {
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Content {
  padding: 0px 10px !important;
  margin: 1.75rem auto;
  bottom: 0px;
}

/* Daterange picker */

.react-bootstrap-daterangepicker-container {
  width: 100%;
}

.required button {
  border-top: 2px solid #d60037;
}

.required button:focus {
  border-top: 2px solid #d60037;
}

input.required:focus {
  border-top: 2px solid #d60037 !important;
}

input.required {
  border-top: 2px solid #d60037;
}

.cal-icon {
  position: absolute;
  top: 36px;
  right: 10px;
  color: #495057;
}

.form-control:disabled,
.form-control[readonly] {
  background: none;
}

/* Modal popup */

/* .modal-footer {display: block;}

.img-pro-content ul li {
    width: 100%;
    overflow: hidden;
    word-break: break-all;
  }

  .image {height: 1024px;}

.image-vertical {height: 768px;} */

.modal-footer {
  display: block;
}

.d-block {
  display: block;
}

.img-pro-content ul li {
  width: 100%;
  overflow: hidden;
  word-break: break-all;
}

/*.image-h {height: 768px; width: 1300px;}
.image-v {height: 768px; width: 550px;}*/
.close-btn {
  position: absolute;
  z-index: 9;
  right: 0px;
}

.close-btn button {
  background: #333;
  border-radius: 50%;
  color: #fff;
  border: 0px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.modal-popup-icon {
  position: absolute;
}

/* img ratio */
.image-ratio-17-25 {
  width: 620px;
  height: 900px;
}

.image-ratio-9-16 {
  width: 510px;
  height: 900px;
}

.image-ratio-16-9 {
  width: 1600px;
  height: 900px;
}

.image-ratio-4-3 {
  width: 1200px;
  height: 900px;
}

.image-ratio-3-4 {
  width: 675px;
  height: 900px;
}

.image-ratio-3-2 {
  width: 1350px;
  height: 900px;
}

.image-ratio-2-3 {
  width: 600px;
  height: 900px;
}

.image-ratio-1-1 {
  width: 900px;
  height: 900px;
}

.image-ratio-unknownLandscape {
  width: 1500px;
}

.image-ratio-unknownPortrait {
  height: 900px;
}

/*.required-field {border-top: 2px solid #d60037;} */

.close {
  position: relative;
  font-size: 25px;
  font-weight: 300;
  top: 0px;
}

.modal-footer {
  padding: 1rem 0px 0px;
}

/*.required-field {border-top: 2px solid #d60037;} */

/*.required button{border-top: 2px solid #d60037!important}; */

/* Tooltip */

.tool,
.tool-side,
.position-r {
  position: relative;
}

.tool .text {
  visibility: hidden;
  width: 120%;
  line-break: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 10;
  top: 25px;
  opacity: 0.8;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
}

.tool .tooltip-width-230
{
  width: 230%;
}

.tool-side .text-side {
  visibility: hidden;
  width: 100%;
  line-break: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 25px;
  opacity: 0.5;
}

.tool .text::after {
  content: "";
  position: absolute;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  bottom: 100%;
  right: 100%;
}

.tool-side .text-side::after {
  content: "";
  position: absolute;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  bottom: 100%;
  right: 50%;
}

.tool:hover .text,
.tool-side:hover .text-side {
  visibility: visible;
}

/*Logout page */

.logout-footer {
  position: fixed;
  bottom: 0;
  margin: 0;
}

.logout-block {
  width: 300px;
  top: 34%;
  border: 1px solid #00aff0;
  left: 40%;
  margin: auto;
  position: absolute;
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  color: red;
}

.logout-sucess {
  font-size: 20px;
  font-weight: 400;
}

.logout-content {
  margin: 0;
  color: #929292;
}

.logout-link {
  color: #00aff0;
}

a.logout-link:hover {
  text-decoration: underline !important;
  color: #00aff0 !important;
  cursor: pointer;
}

/* Media Query */

@media screen and (min-width: 1280px) and (max-width: 1366px) {

  .allocate-col-1-bg h5,
  .allocate-col-2-bg h5,
  .allocate-col-3-bg h5,
  .allocate-col-4-bg h5,
  .allocate-col-5-bg h5,
  .allocate-col-6-bg h5 {
    font-size: 11px !important;
    font-weight: normal;
  }

  .carousel-control-prev,
  .carousel-control-next {
    min-height: 45px;
    width: 4%;
  }

  .img-gallery-carousel-inner {
    width: 100%;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1900px) {
  .view-close-icon-toggle {
    position: absolute;
    right: -15px;
    top: -34px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1900px) {
  .ReactModal__Content {
    margin: 1rem auto;
  }

  /*  .image-h {height: 680px;}
    .image-v {height: 680px;} */

  .image-ratio-17-25 {
    width: 410px;
    height: 600px;
  }

  .image-ratio-9-16 {
    width: 345px;
    height: 600px;
  }

  .image-ratio-16-9 {
    width: 1067px;
    height: 600px;
  }

  .image-ratio-4-3 {
    width: 800px;
    height: 600px;
  }

  .image-ratio-3-4 {
    width: 450px;
    height: 600px;
  }

  .image-ratio-3-2 {
    width: 900px;
    height: 600px;
  }

  .image-ratio-2-3 {
    width: 400px;
    height: 600px;
  }

  .image-ratio-1-1 {
    width: 600px;
    height: 600px;
  }

  .image-ratio-unknownLandscape {
    width: 800px;
  }

  .image-ratio-unknownPortrait {
    height: 600px;
  }
}

.singleImageViewLandscape {
  width: 880px;
}

.singleImageViewPortrait {
  height: 880px;
}

.mr5 {
  margin-right: 8px;
}

.airing-reset {
  position: absolute;
  top: 40px;
  right: 0px;
}

.active-submenu {
  color: #fff;
}

.btn-acknowledge {
  color: #b21dac;
}

.btn-acknowledge:hover {
  background: #b21dac;
  color: #fff;
}

.btn-acknowledge:before {
  content: "\f164";
  display: inline-block;
  border-radius: 50px;
  background: #b21dac;
  color: #fff;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  text-align: center;
  font: normal normal normal 12px/1 FontAwesome;
  padding-top: 3px;
  border: 1px solid #b21dac;
}

.btn-acknowledge:hover:before {
  border-radius: 50px;
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
}

.btn-revert {
  margin-bottom: 4px;
  color: #ff8300;
}

.btn-revert:hover {
  background: #ff8300;
  color: #fff;
}

.btn-revert:before {
  content: "\f007";
  display: inline-block;
  border-radius: 50px;
  background: #ff8300;
  color: #fff;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  text-align: center;
  font: normal normal normal 12px/1 FontAwesome;
  padding-top: 3px;
  border: 1px solid #85c63f;
}

.btn-revert:hover:before {
  border-radius: 50px;
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
}

.btn-img-reset {
  margin-bottom: 4px;
  color: #009dd9;

}

.btn-img-reset:hover {
  background: #009dd9;
  color: #fff;
}

.btn-img-reset:before {
  content: "\f007";
  display: inline-block;
  border-radius: 50px;
  background: #009dd9;
  color: #fff;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  text-align: center;
  font: normal normal normal 12px/1 FontAwesome;
  padding-top: 3px;
  border: 1px solid #85c63f;
}

.btn-img-reset:hover:before {
  border-radius: 50px;
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
}

.help_icon {
  font-size: 1.5em !important;
  line-height: 0.75em !important;
  vertical-align: -15%;
  position: relative;
  top: 18px;
  cursor: pointer;
}

/* help screen demo tour */

.react-joyride__tooltip button[data-action="primary"] {
  background: #85c63f !important;
}

.react-joyride__tooltip button[data-action="back"] {
  color: #85c63f !important;
}

.react-joyride__tooltip button[data-action="close"] {
  display: none !important;
}

.react-joyride__beacon span:first-child {
  background-color: #85c63f !important;
  border: 2px solid #85c63f !important;
}

.react-joyride__beacon span:last-child {
  border: 2px solid #85c63f !important;
  background-color: rgba(0, 157, 217, 0.2) !important;
}

.react-joyride__overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.react-joyride__tooltip {
  padding: 0 !important;
  border-radius: 0px !important;
}

.react-joyride__tooltip div div {
  padding: 0 !important;
}

.react-joyride__tooltip div:nth-child(2) {
  padding: 0 15px 15px !important;
  margin-top: 0px !important;
}

.react-joyride__tooltip div div.background-both {
  background-image: url(../images/both-left.png), url(../images/both-right.png);
  background-position: left top, right top;
  position: relative;
  background-repeat: no-repeat, no-repeat;
  background-size: 80px 115px, 35px 50px;
  padding: 50px 25px 20px 45px !important;
  text-align: center !important;
  color: #000;
}

.react-joyride__tooltip div div.background-left {
  background-image: url(../images/left.png);
  background-position: left top;
  position: relative;
  background-repeat: no-repeat;
  background-size: 120px 84px;
  padding: 50px 25px 20px 45px !important;
  text-align: right !important;
  color: #000;
}

.react-joyride__tooltip div div.background-right {
  background-image: url(../images/right.png);
  background-position: right top;
  position: relative;
  background-repeat: no-repeat;
  background-size: 120px 84px;
  padding: 50px 45px 20px 25px !important;
  text-align: left !important;
  color: #000;
}

.react-joyride__tooltip div div span {
  font-weight: 600;
  color: #ed721e;
}

.col-md-3 div:focus {
  outline: none;
}

.form-group div:focus {
  outline: 1px solid #7aacfe;
}

.complete-text {
  color: black !important;
  font-weight: normal !important;
  font-size: 13px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.check-mark {
  width: 15%;
  margin-bottom: 5px;
}

.top-hide-panel {
  height: 9px;
  background: #d7dfe5;
  text-align: center;
  width: 101%;
}

.top-toggle-image {
  position: relative;
  bottom: 8px;
  cursor: pointer;
}

.d-none {
  display: none;
}

.pager {
  padding-top: 25px;
}

.-pageJump input {
  border: none !important;
  margin: 0 !important;
  width: 40px !important;
  padding: 0 !important;
}

.text-right {
  text-align: right;
}

.mb-15 {
  margin-bottom: 15px;
}

.picky__dropdown .option:focus {
  border: 1px solid #009dd9;
}

/* Notification CSS */

.s-alert-close::before,
.s-alert-close::after {
  width: 2px;
  height: 40%;
}

.s-alert-box {
  padding: 15px 22px;
  width: 250px;
  text-align: center;
  z-index: 10000;
}

.s-alert-close {
  top: 0;
  right: -2;
}

.notification {
  padding-right: 10px;
}

.s-alert-info {
  background: #effce9;
  border: 1px solid #8fca75;
}

.s-alert-info .s-alert-box-inner {
  color: #8fca75;
}

.s-alert-info .s-alert-close::before,
.s-alert-info .s-alert-close::after,
.s-alert-info .s-alert-close:hover::before,
.s-alert-info .s-alert-close:hover::after,
.s-alert-info .s-alert-effect-bouncyflip .s-alert-close::after,
.s-alert-info .s-alert-effect-bouncyflip .s-alert-close::before,
.s-alert-info .s-alert-effect-bouncyflip .s-alert-close:hover::after,
.s-alert-info .s-alert-effect-bouncyflip .s-alert-close:hover::before {
  background: #8fca75;
}

.s-alert-success {
  background: #deecf2;
  border: 1px solid #009dd9;
}

.s-alert-success .s-alert-box-inner {
  color: #009dd9;
}

.s-alert-success .s-alert-close::before,
.s-alert-success .s-alert-close::after,
.s-alert-success .s-alert-close:hover::before,
.s-alert-success .s-alert-close:hover::after,
.s-alert-success .s-alert-effect-bouncyflip .s-alert-close::after,
.s-alert-success .s-alert-effect-bouncyflip .s-alert-close::before,
.s-alert-success .s-alert-effect-bouncyflip .s-alert-close:hover::after,
.s-alert-success .s-alert-effect-bouncyflip .s-alert-close:hover::before {
  background: #009dd9;
}

.s-alert-error {
  background: #f4dee3;
  border: 1px solid #d60037;
}

.s-alert-error .s-alert-box-inner {
  color: #d60037;
}

.s-alert-error .s-alert-close::before,
.s-alert-error .s-alert-close::after,
.s-alert-error .s-alert-close:hover::before,
.s-alert-error .s-alert-close:hover::after,
.s-alert-error .s-alert-effect-bouncyflip .s-alert-close::after,
.s-alert-error .s-alert-effect-bouncyflip .s-alert-close::before,
.s-alert-error .s-alert-effect-bouncyflip .s-alert-close:hover::after,
.s-alert-error .s-alert-effect-bouncyflip .s-alert-close:hover::before {
  background: #d60037;
}

.s-alert-warning {
  background: #efe1ee;
  border: 1px solid #b21dac;
}

.s-alert-warning .s-alert-box-inner {
  color: #b21dac;
}

.s-alert-warning .s-alert-close::before,
.s-alert-warning .s-alert-close::after,
.s-alert-warning .s-alert-close:hover::before,
.s-alert-warning .s-alert-close:hover::after,
.s-alert-warning .s-alert-effect-bouncyflip .s-alert-close::after,
.s-alert-warning .s-alert-effect-bouncyflip .s-alert-close::before,
.s-alert-warning .s-alert-effect-bouncyflip .s-alert-close:hover::after,
.s-alert-warning .s-alert-effect-bouncyflip .s-alert-close:hover::before {
  background: #b21dac;
}

/* Allocator workflow css */

h1.txt-violet {
  padding-bottom: 20px;
  border-bottom: 1px solid #b21dac;
  display: inline-block;
  margin-bottom: 0px;
}

.background-white {
  background: #fff;
}

.qc-card-panel {
  position: absolute;
  border: 1px solid red;
  height: 100px;
}

.control-label1 {
  margin-bottom: 3px;
  text-align: center;
}

.daterangepicker:before {
  border: 0;
}

.daterangepicker:after {
  border: 0;
}

.temp-div {
  margin-top: 31px;
}

.temp-label {
  position: relative;
  top: 589px;
}

.perm-panel {
  margin-top: -43px;
}

.add-icon {
  position: absolute;
  right: 6px;
  top: 6px;
}

.switch-field {
  display: flex;
  overflow: hidden;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 10px;
  line-height: 1;
  text-align: center;
  padding: 4px 8px;
  margin-right: -1px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease-in-out;
  font-weight: bold;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked+label {
  color: #009dd9;
  box-shadow: none;
  border: 1px solid #009dd9;
}

.switch-field label {
  border-radius: 2px;
}

.form-control {
  padding: 7px 30px 7px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-block {
  padding-left: 5px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.header-bottom {
  border-bottom: 1px solid #dee2e6;
}

/* Custom Scroll css */

::-webkit-scrollbar {
  width: 6px !important;
}

::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, .87) !important; */
  box-shadow: inset 0 0 5px #e4e8eb !important;

  border-radius: 8px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #707276 !important; */
  background: #d7dfe5 !important;

  border-radius: 8px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d7dfe5 !important;
}

.location-dropdown {
  padding-top: 10px;
}

.allocator-reset {
  float: right;
  margin-right: 10px;
  position: relative;
  bottom: 8px;
}

.filter-block {
  display: inline-block;
  color: #b21dac;
  font-size: 12px;
  cursor: pointer;
  margin-right: 15px;
  position: relative;
  top: 1px;
  right: 2px;
}

.filter-block i {
  font-size: 14px;
  margin-right: 5px;
  top: 1px;
  position: relative;
}

.moreFilterBlock {
  max-height: 0;
  overflow-y: hidden;
  transition: ease-in-out 0s max-height;
}

.moreFilterBlock.opened {
  max-height: 1000px;
  transition: ease-in-out 0.1s max-height;
  overflow-y: inherit;
}

.pl20 {
  padding-left: 20px;
}

.pr20 {
  padding-right: 20px;
}

.pt20 {
  padding-top: 20px;
}

.pr30 {
  padding-right: 30px;
}

.reset-icon {
  margin-right: 2px !important;
}

.f-r {
  float: right;
}

.gallery-icons {
  position: absolute;
  right: 5px;
  top: 0px;
}

.gallery-edit-icon:before {
  content: "\F040";
  display: inline-block;
  border-radius: 50px;
  background: #009dd9;
  color: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  font: normal normal normal 12px/1 FontAwesome;
  padding-top: 3px;
  border: 1px solid #009dd9;
}

.gallery-improvisation-icon:before {
  content: "\f044";
  display: inline-block;
  border-radius: 50px;
  background: #b21dac;
  color: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  font: normal normal normal 12px/1 FontAwesome;
  padding-top: 3px;
  border: 1px solid #b21dac;
}

.gallery-approve-icon:before {
  content: "\f00c";
  display: inline-block;
  border-radius: 50px;
  background: #85c63f;
  color: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  font: normal normal normal 12px/1 FontAwesome;
  padding-top: 3px;
  border: 1px solid #85c63f;
}

.gallery-reject-icon:before {
  content: "\f00d";
  display: inline-block;
  border-radius: 50px;
  background: #d60037;
  color: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  font: normal normal normal 12px/1 FontAwesome;
  padding-top: 3px;
  border: 1px solid #d60037;
}

.serial_number_header {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  display: flex;
}

.serial_number {
  width: 95%;
  position: relative;
  left: 25px;
}

.picky__dropdown .option input[type="radio"] {
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.contentTypeClass .picky__input::after {
  right: 20px;
  top: 13px;
}

.contentTypeClass .picky__input {
  padding: 5px 45px 5px 5px;
}

.contentTypeReset {
  position: absolute;
  top: 42px;
  right: 5px;
}

.contentTypeRefresh {
  position: absolute;
  top: 41px;
  right: 5px;
}

.opacity-50 {
  opacity: 0.5;
}

.labelCheckbox {
  display: inline-block;
  float: right;
  position: relative;
  right: 7px;
}

.labelCheckbox input:focus {
  outline: none;
}

.labelCheckbox input[type="checkbox"] {
  width: 12px;
  height: 12px;
}

.missingImageLabel {
  margin-left: 5px;
}

.allowClientLabel {
  margin-left: 5px;
  font-size: 14px;
}

.react-switch {
  top: 2px;
  margin-left: 20px;
}

.downloadCSV {
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  background: none;
  border: none;
  color: #ff6700;
}

.input-custom-channel {
  border-top: 1px solid #009dd9;
  height: 38px;
  width: 100%;
  padding: 0 28px;
  border-bottom: 0.5px solid #ededed;
  display: flex;
  border-left: none;
  border-right: none;
  outline: none;
  background: url(../images/search.svg) no-repeat;
  background-position: left center;
}

.input-custom-channel input {
  width: 80%;
  border: none;
  outline: none;
  height: 36px;
  font-size: 18px;
  text-indent: 30px;
  margin: auto;
  border-bottom: 1px solid #5555;
  line-height: 20px;
  color: #929292;
  border-top: 1px solid #5555;
}

#myBtn
{
    color: red;
    position: fixed;
    bottom: 30px;
    right: 18px;
    z-index: 99;
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 8px;
    background-color: #009dd9;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50px;
}
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    display: flex !important;
    justify-content: center;
    align-items: center;
    color: rgb(252, 250, 250);
}

@media only screen and (max-width: 1350px) {
  .allowClientLabel {
    font-size: 12px;
  }
}
