/* CSS Document */

.collapse-arrow {
  position: absolute;
  top: 50%;
  right: 3px;
  cursor: pointer;
}
.panel-slider,
a[data-toggle="collapse"] {
  position: relative;
}
.panel-slider {
  width: 250px;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 9;
  transition: all 0.3s;
}
.panel-slider.active {
  margin-left: -250px;
}
.panel-slider .sidebar-header {
  padding: 20px;
  background: #6d7fcc;
}

.panel-slider ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #47748b;
}
.panel-slider ul p {
  color: #fff;
  padding: 10px;
}
.panel-slider ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}
.panel-slider ul li a:hover {
  color: #7386d5;
  background: #fff;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.container-panel {
  width: calc(100% - 0px);
  min-height: 100vh;
  transition: all 0.3s;
  top: 0;
  right: 0;
}
.container-panel .active {
  width: 100%;
}

.panel-slider .active .collapse-arrow i {
  transform: rotate(180deg);
}
