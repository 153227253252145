.fs-wrap {
  display: inline-block;
  cursor: pointer;
  line-height: 1;
  width: 100%;
}
.fs-wrap:focus {
  border-top: 2px solid #0099e9;
  box-shadow: 0 0 0;
  outline: 0;
}
.fs-arrow,
.fs-dropdown,
.fs-label-wrap {
  background: url(../images/select-arrow.png) top 12px right 9px no-repeat #fff;
}

.fs-label-wrap {
  position: relative;
  background-color: #fff;
  cursor: default;
}
.fs-label-wrap .fs-label {
  padding: 12px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  color: #7a7a7a;
}
.fs-label {
  padding: 0;
}

.fs-dropdown {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  width: 100%;
  margin-top: 0;
  z-index: 1000;
}
.fs-dropdown .fs-options {
  max-height: 200px;
  overflow: auto;
}

.fs-search {
  padding: 4px 8px;
}
.fs-search input {
  border: none !important;
  box-shadow: none !important;
  outline: 0;
  padding: 4px 0;
  width: 100%;
}
.fs-optgroup-label,
.fs-option,
.fs-search {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: default;
}
.fs-search {
  padding: 5px 8px;
}
.fs-option:last-child {
  border-bottom: none;
}

.fs-option {
  cursor: pointer;
  word-break: break-all;
  font-size: 12px;
}
.fs-option.disabled {
  opacity: 0.4;
  cursor: default;
}
.fs-option.hl {
  background-color: #f5f5f5;
}

.fs-wrap.multiple .fs-option {
  position: relative;
  padding-left: 30px;
}
.fs-wrap.multiple .fs-checkbox {
  position: absolute;
  display: block;
  width: 30px;
  top: 0;
  left: 0;
  bottom: 0;
}
.fs-wrap.multiple .fs-option .fs-checkbox i {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 14px;
  height: 14px;
  border: 1px solid #aeaeae;
  border-radius: 2px;
  background-color: #fff;
}
.fs-wrap.multiple .fs-option.selected .fs-checkbox i {
  background-color: #3e8acc;
  border-color: transparent;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABMSURBVAiZfc0xDkAAFIPhd2Kr1WRjcAExuIgzGUTIZ/AkImjSofnbNBAfHvzAHjOKNzhiQ42IDFXCDivaaxAJd0xYshT3QqBxqnxeHvhunpu23xnmAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: center;
}
.fs-optgroup-label {
  font-weight: 700;
  text-align: center;
}
.hidden {
  display: none;
}
